.animation::-webkit-input-placeholder {
    -webkit-transition: all 0.3s ease-in;
         transition: all 0.3s ease-in;
         opacity: 1;
}   
.animation:focus::-webkit-input-placeholder {
    -webkit-transition: all 0.3s ease-in-out;
         transition: all 0.3s ease-in-out;
         opacity: 0;
}
