#contact {
    width:100%;
}

#contact h5 {
	line-height: 1.5;
    font-size:18px;
}

#form {
    width:100%;
}

#contact .button-leweb {
	width: 100%;
    height: 45px;
    font-size:18px;
    font-family: Poppins;
}

.contact-info {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

#contact input, #contact textarea {
    font-size: 14px;
    font-family: Poppins;
}

#success strong {
    font-size: 16px;
    font-family: Poppins;
}

#success p {
    font-size: 14px;
    font-weight: 200;
    color: rgb(51, 51, 51);
    font-family: Poppins;
}
