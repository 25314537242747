html {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.TextAreaDiv::placeholder {
  padding-top: 18vh;
}
@media only screen and (max-width: 768px) {
  .TextAreaDiv::placeholder {
    padding-top: 15vh;
  }
}
::placeholder {
    color: #B4BECF;
}