@media only screen and (max-width:768px)
{
    .width100
    {
        min-width:100%;
    }
    .marginAuto
    {
        margin: auto;
    }
    .width90
    {
        min-width: 90%;

    }
    .noPaddingNoMargin
    {
       margin:0%!important;
       padding:0%!important;
    }
    .noBg
    {
        border:none;
        outline: none;
        box-shadow: none!important;
        background-color: transparent!important;

    }
    .modalCenter
    {
        left:0!important;
        margin:0 5vw!important;

    }

}