.ReactModal__Overlay {
    opacity: 0;
    transition: all .3s ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transition: all fade-in 3s
  }