
@font-face {
  font-family: Poppins;
  src: url(../fonts/Poppins-Light.ttf);
}
  

/* GLOBAL STYLES */
* {
	margin: 0px;
	padding: 0px;
}

#body {
    background: #EDF2FA;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #333333;
    line-height: 28px;
    text-align: center;
    overflow-x: hidden !important;
    margin: auto !important;
	background: url(../images/full_back.svg) no-repeat;
	background-size: cover;
	
}



a, a:hover, a:active, a:focus {
    border: 0 none;
    outline: 0 none;
    text-decoration: none;
	cursor: pointer;
}

/* TYPOGRAPHIE */

h1,
h2 {
	font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

h1 {
    font-size: 62px;
    line-height: 62px;
	font-weight: 500;
}

h2 {
    font-size: 32px;
    line-height: 42px;
}

h3 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;
}

h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
}

h5 {
    font-size: 18px;
    font-weight: 300;
}

p {
    font-size: 14px;
    font-weight: 200;
	margin: 0px;
}


/* FORM GROUPS */

.form-control {
	background: none repeat scroll 0 0 rgba(255, 255, 255, 0.5);
	border-radius: 0px;
    box-shadow: none;
    height: 57px;
    padding-left: 20px;
}

/* CUSTOM CLASSES */

.white-text {
	color: #fff;
}

.colored-line-small-center {
	background: #008ed6;
	display: block;
	margin: auto;
    height: 1px;
    width: 25%;
    z-index: 1;
	margin-bottom: 5px;
}

.colored-line-right {
	background: #008ed6;
	display: block;
	float: right;
    height: 1px;
    width: 165px;
    z-index: 1;
}

.button-leweb {
	background: linear-gradient(177.19deg, #8470FE 2.03%, #6764FF 20.93%, #6048F3 42.31%, #4E33F1 78.65%, #3F30ED 94.46%), #6485F3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 34px;
	border: none;
	color: rgb(253, 253, 253);
	font-weight: 400;
	border-radius: 28px;
	height: 45px;
	padding-left: 18px;
	padding-right: 18px;

	
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease; 
}


.button-leweb:hover {
	background: #6555c9;
}

.text_collumn {
	padding: 20% 5% 20% 5%;
	width: 50%;
	text-align: left;
}
.no_padding {
	padding: 00% 5% 0% 5%;
}
.read_feedback {
	background: linear-gradient(180deg, #261772 0%, #150B43 100%);
    color: white;
}
.tag_wrapper {
	display: flex;
	flex-direction: row;
}
.tag {
	height: auto;
	background-color: #EDE9FF;
	color: #1D0092;
	border-radius: 30px;
	padding: 0px 10px;
    font-size: 10px;
	margin: 10px 5px;
}

/* SECTIONS */

section {
	padding: 120px 0px 100px 0px;
}
/* HEADER */

#header {
	background-size: cover;
	height: 100vh;
	padding: 0px;
	background-color: #050121;
}

#header h1 {
	font-weight: 600;
}

.transparent-img {
	overflow-x: hidden !important;
    margin: auto !important;
	background: url(../images/hero_background.svg) no-repeat center center;
	background-size: cover;
	height: 100vh;
	z-index: 999;

}


#header .container {
	height: 100vh;
	min-width: 100vw !important;
	padding: 0px;	
	z-index: 998;
}
.container {
	display: flex;
    flex-direction: column;
	justify-content: center;
}
.col-md-6 {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#header .col-md-8 {
	margin-top: -110px;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
	z-index: 999;
}

#header .logo {
	margin-bottom: 15px;
	height: 154.83006286621094px;
	width: 162.91798400878906px;
}


#header .fa-angle-down {
	text-align: center;
	font-size: 48px;
	color: #fff;
	
	
	-webkit-animation: leweb_fade_move_down 2s ease-in-out infinite;
	-moz-animation: leweb_fade_move_down 2s ease-in-out infinite;
	animation: leweb_fade_move_down 2s ease-in-out infinite;
   }

.fadein {
    opacity: 0;
    -moz-transition: opacity 1.5s;
    -webkit-transition: opacity 1.5s;
    -o-transition: opacity 1.5s;
    transition: opacity 1.5s;
}

body.loaded .fadein {
    opacity: 1;
}

/* animations for loading - all browsers */ 

@keyframes show {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes show {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes show {
    from { opacity: 0; }
    to   { opacity: 1; }
}


/* Opera < 12.1 */
@-o-keyframes show {
    from { opacity: 0; }
    to   { opacity: 1; }
}


#links {
	height: 10%;
	display: flex;
	-webkit-animation: show 2s ease-in; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: show 2s ease-in; /* Firefox < 16 */
	  -o-animation: show 2s ease-in; /* Opera < 12.1 */
		 animation: show 2s ease-in;
	flex-direction: row;
	justify-content: space-between;
	width: 80%;
	position: absolute;
	left:10%;
	align-items: center;
	font-size: calc(0.4vh + 0.4vw + 0.4vmin);
}

#links a {
	color:white;
}

#logo-text:hover, .links-margin:hover{
	opacity:0.7;
}

#header-logo {
	display: flex;
	align-items: center;
}

#logo-text {
	margin-left: 0.5vw;
}

#menu-icon {
	display: none;
}

#buttons {
    display: flex;
    flex-direction: row;
	white-space: nowrap;
   	text-align: center;
}

.links-margin{
	margin-right: 0.7vw;
}

#header-button-1 {
	border: none;
	background: none;
	box-sizing: border-box;
	padding: 0% 2%;
	background: transparent;
}

#header-button-2 {
	border:1px solid white;
	border-radius: 34px;
	box-sizing: border-box;
	padding: 0% 2%;
	background: transparent;
}


#header-button-3 {
	border-radius: 34px;
	background: linear-gradient(177.19deg, #8470FE 2.03%, #6764FF 20.93%, #6048F3 42.31%, #4E33F1 78.65%, #3F30ED 94.46%),
	linear-gradient(0deg, #6485F3, #6485F3);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border:1px solid transparent;
	border-radius: 34px;
	box-sizing: border-box;
	padding: 0% 2%;
	font-weight: bolder;
}

#header-button-3:hover {
	background: #6555c9;
} 

#mobile-nav {
	display: none;
	height: 0;
	opacity: 0;
}


/* ABOUT */

#about i {
	color: #fff;
	font-size: 75px;
	background: #008ed6;
	border-radius: 50%;
	line-height: 175px;
	height: 175px;
	width: 175px;
}

/* SUBSCRIBE */

#subscribe {
	background: url(../images/purple.png) no-repeat fixed center center / cover rgba(0, 0, 0, 0);
	padding: 0px;

}

#subscribe .icontop {
	position: absolute;
	color: #fff;
	margin: -30px auto;
	right: 0;
	left: 0;
	font-size: 25px;
	border-radius: 50%;
	line-height: 65px;
	height: 65px;
	width: 65px;
	background: rgba(0, 142, 214, 0.7);	
	box-shadow: 0 0 0 1px #008ed6;
}

#subscribe .row {
	padding: 100px 0px 120px 0px;
}

#subscribe h5 {
	margin-bottom: 45px;
	line-height: 1.5;
}

#contact h5 {
	line-height: 1.5;
}

#subscribe .form-control {
	display: inline-block;
	border: 1px solid #fff;
    width: 320px;
}

#subscribe .form-control::-moz-placeholder {
	color: #fff;
}

#subscribe .button-leweb {
	display: inline-block;
}

#subscribe #mc_form label {
	display: block;
	font-size: 12px !important;
	margin-top: 10px;
	font-weight: normal;
	position: relative;
	color: #fff;
}

#subscribe #mc_form .valid:before {
        font-family: FontAwesome;
        top:0;
        content: "\f058";
		font-size: 14px;
		margin-right: 4px;
		color: #b8c000;
}

#subscribe #mc_form .error:before {
        font-family: FontAwesome;
        top:0;
        content: "\f071";
		font-size: 14px;
		margin-right: 4px;
		color: #bb1720;
}


/* CONTACT */

#contact .button-leweb {
	width: 100%;
    height: 45px;
}

#contactForm {
	width:50%;
	margin-left: 25%;
}
/* 
#success {
	display: none;
} */

#success i {
	color: #b8c000;
	font-size: 28px;
	display: block;
}

/* FOOTER */

#footer {
	border-top: 1px solid #ccc;
	padding: 25px 0px;
}

#footer p {
	margin: 0px 0px -5px;
}

#footer .social {
	float: right;
	padding: 0px 20px;
}

#footer .fa {
	font-size: 24px;
	color: rgba(96, 22, 234, 0.5);
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	padding: 0 5px 0 0;
}

#footer .fa-linkedin-square{
	padding: 0 0 0 0;
}

#footer .fa:hover {
	color: #008ed6;
}

.quote {
	background-color: #EDF2FA;
    margin: -110px auto;
    width: 80%;
    border-radius: 40px;
    text-align: left;
    padding: 40px;
	box-shadow: 0px -14px 22px rgba(40, 21, 159, 0.5);
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;

}  
.small {
	transform: rotate(7.7deg);
	padding: 20px;
    color: #2F1E1E;
	background-color: #EDF2FA;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	margin: -180px auto;
	box-shadow: 0px -14px 22px rgba(40, 21, 159, 0.2);
	text-align: left;
	

}
.second {
	transform: rotate(0deg);
	margin: 50px auto;

}
.third {
	transform: rotate(-4.17deg);
	margin: 0px auto;
}
.row {
	display: flex;
}


@keyframes show2 {
    0% { opacity: 0; }
	60% { opacity: 0; }
 	100%  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes show2 {
    0% { opacity: 0; }
	60% { opacity: 0; }
 	100%  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes show2 {
	90% { opacity: 0; }
    60% { opacity: 0; }
 	100%  { opacity: 1; }
}


/* Opera < 12.1 */
@-o-keyframes show2 {
	90% { opacity: 0; }
    60% { opacity: 0; }
 	100%  { opacity: 1; }
}

#fadein{
	-webkit-animation: show2 2.5s ease-in; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: show2 2.5s ease-in; /* Firefox < 16 */
	  -o-animation: show2 2.5s ease-in; /* Opera < 12.1 */
		 animation: show2 2.5s ease-in;
}


.quote {	 
	-webkit-animation: show2 3.5s forwards; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: show2 3.5s forwards; /* Firefox < 16 */
	  -o-animation: show2 3.5s forwards; /* Opera < 12.1 */
		 animation: show2 3.5s forwards;
	
}
			

/* RESPONSIVE DESIGN */

/* Extra Small devices (tablets, 446px and less) */
@media (max-width: 456px) { 
	
	#mc_form .form-control {
		width: 100%;
		margin-bottom: 15px;
	}
	
	.button-leweb {
		width: 80%;

	}
	.row{
		flex-direction: column-reverse;
		margin:0px;
    }
	.row .reverse{ 
		flex-direction: column;
    }
	section {
		padding: 30px 0px;
	}

	.small {
	 margin: 50px 20px;
	}
	.second {
		margin: 10px 20px;
	}
	.third {
		margin: 50px 20px;
	}
	.quote {
		padding: 25px;
		padding: 0% 10% 0% 10%;
	}
	.col-md-6 {
		padding: 0px;
	}



}
/* Small devices (tablets, 768px and less) */
@media (max-width: 768px) { 

	body {
		font-size: 14px;
		line-height: 20px;
	}
	h1 {
		font-size: 44px;
		line-height: 58px;
	}

	h2 {
		font-size: 24px;
		line-height: 38px;
	}

	h3 {
		font-size: 18px;
		line-height: 30px;
	}

	h4 {
		font-size: 16px;
		line-height: 26px;
	}

	h5 {
		font-size: 14px;
		line-height: 20px;
	}
	
	.clocktitle{
		font-size: 11px;
	}

	.numbers {
		font-size: 44px;
	}
	
	.row {
		flex-direction: column-reverse;
		margin:0px;
    }
	.reverse{ 
		flex-direction: column;
    }
 
	.text_collumn {
		width: 100%;
		padding: 0% 10% 0% 10%;
	}
	section {
		padding: 30px 0px;
	}
	.small {
		margin: 50px 20px;
	   }
	.second {
		   margin: 10px 20px;
	   }
	.third {
		   margin: 50px 20px;
	   }
	.quote {
		padding: 25px;
		width: 85%;
	}
	.col-md-6 {
		padding: 0px;
	}
	.form-group {
		padding-left: 0px;
		padding-right: 0px;
	}
	#header .logo {
		display: none;
	}
	
	#header h1 {
		font-size: 8vmin;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 992px) {	
	
	#about .middle {
		margin-top: 65px;
		margin-bottom: 65px;
	}
	.row {
		flex-direction: column-reverse;
		margin:0px;
    }
	.reverse{ 
		flex-direction: column;
    }
	.text_collumn {
		width: 100%;
		padding: 0% 0% 5% 0%;
	}
	section {
		padding: 30px 0px;
	}
	.small {
		margin: 50px 20px;
	   }
	 .second {
		   margin: 10px 20px;
	   }
	.third {
		   margin: 50px 20px;
	   }
	.quote {
		padding: 25px;
		width: 85%;
	}
	.col-md-6 {
		padding: 0px;
	}
	.form-group {
		padding-left: 0px;
		padding-right: 0px;
	}
   
	
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
	#links {
		width: 100%;
        left: 0%;
    	padding: 5%;
		font-size: calc(0.5vh + 0.5vw + 0.5vmin);

	}
	.links-margin{
		margin-right: 0.9vw;
	}
		
}

@media (max-width: 777px) {
	#logo-text{
		margin-left: 1.5vw;
	}

	#buttons {
		display:none;
	}

	#menu-icon {
		display:flex;
	}
	
	#links {
		width: 100%;
		padding: 0% 5%;
		left: 0%;
		font-size: calc(1vh + 1vw + 1vmin);
	}

	#mobile-nav {
		opacity: 100;
		height: 100vh;
		width:100%;
		margin: auto;
		position: relative;
		display: none;
		flex-direction: column;
		justify-content: center;
		font-size: 5vh;
		background: url(../images/hero_background.svg) no-repeat center center;
		background-size: cover;
		background-color: #050121;

	}

	#mobile-nav a {
		color: white;
	}

	#third-button {
		background: linear-gradient(177.19deg, #8470FE 2.03%, #6764FF 20.93%, #6048F3 42.31%, #4E33F1 78.65%, #3F30ED 94.46%), #6485F3;
    	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border:1px solid transparent;
		border-radius: 34px;
		margin: 0vh auto;
		font-size: 3vh;
    	height: 6vh;
    	width: 30vh;
	}

	#second-button {
		border:1px solid white;
		border-radius: 34px;
		background: transparent;
		font-size: 3vh;
    	height: 6vh;
    	width: 30vh;
    	margin: 0vh auto 10vh auto;
	}

	#first-button {
		border: none;
		background: none;
		background: transparent;
		font-size: 3vh;
    	height: 6vh;
    	width: 20vw;
    	margin: 0vh auto 10vh auto;
	}

	#mobile-nav img {
		position: absolute;
		top: 2vh;
    	left: 2vh;
	}

  }

