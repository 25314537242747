::-webkit-scrollbar  {
    height: 12px;
    width: 8px;
}
::-webkit-scrollbar-track-piece {
    background-color: rgb(242, 246, 253);
}
/* The Draggable Scrolling Handle */
::-webkit-scrollbar-thumb {
    background-color: rgb(86, 49, 233);
    width: 15%;
    border-radius: 5px;
}
div.button:hover{
    background-color: rgba(86, 49, 233, 0.74);
    border:none;
}
div.button.active{
    background-color: rgba(86, 49, 233, 0.74);
    border:none;
}
div.button:focus {
    outline: unset;
}
div.button{
    border-radius: 6px;
}