.lds-ring {
  display: flex;
  position: relative;
  width: 0px;
  height: 0px;
  left: 35%; 
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 26px;
  margin-top: 6px;
  margin-left: calc(5vw - 80px);
  border: 4px solid #fff;
  border-radius: 50%;
  -webkit-animation:spin 1.5s linear infinite;
  -moz-animation:spin 1.5s linear infinite;
  animation:spin 1.5s linear infinite;
  border-color: #F2F2F3 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@media only screen and (max-width:768px) {
  .lds-ring {
    width: 0px;
    height: 0px;
    left: calc(50% - 90px);
  }
  .lds-ring div {
    width: 25px;
    height: 25px;
    margin: 4.5px;
  }
}

@media only screen and (min-width:1176px) and (max-width:1177px) {
  .lds-ring div {
    width: 20px;
    height: 22px;
    margin-top: 5px;
  } 
}

@media only screen and (min-width:1280px) and (max-width:1281px) {
  .lds-ring div {
    width: 20px;
    height: 22px;
    margin-top: 5px;
  } 
}

@media only screen and (min-width:1360px) and (max-width:1361px) {
  .lds-ring div {
    width: 20px;
    height: 22px;
    margin-top: 5px;
  } 
}

@media only screen and (min-width:1366px) and (max-width:1367px) {
  .lds-ring div {
    width: 20px;
    height: 22px;
    margin-top: 5px;
  } 
}

@media only screen and (min-width:1368px) and (max-width:1369px) {
  .lds-ring div {
    width: 20px;
    height: 22px;
    margin-top: 5px;
  } 
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }